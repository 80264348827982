<template>
  <div class="soloevent-wrapper unilanding">
    <div>
      <!-- //-Menu-starts -->
      <div class="nav-wrapper">
        <nav class="navbar navbar-expand-lg navbar-dark">
          <div class="container">
            <a class="navbar-brand" href="index.html">
              <img src="../assets/KU-logowhite.png" alt="" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <router-link :to="{ name: 'Nest' }" class="nav-link">
                    <img
                      class="nest-logo"
                      src="../assets/nest-logo.png"
                      alt=""
                    />
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'pupa' }" class="nav-link">
                    <img
                      class="pupa-logo"
                      src="../assets/pupa-logo.png"
                      alt=""
                    />
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <!-- //-Menu-end -->
      <div class="unibanner-wrapper">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(banner, bannerIndex) in banners"
              :key="bannerIndex"
              :data-bs-slide-to="bannerIndex"
              :class="bannerIndex == 0 ? 'active' : ''"
              aria-current="true"
              :aria-label="'Slide ' + bannerIndex"
            >
              <img :src="banner.image" class="d-block w-100" alt="img" />
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-5">
                    <h2>{{ banner.title }}</h2>
                    <p>
                      {{ banner.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="carousel-item">
              <img
                src="../assets/unibanner-img.jpg"
                class="d-block w-100"
                alt="img"
              />
            </div>
            <div class="carousel-item">
              <img
                src="../assets/unibanner-img.jpg"
                class="d-block w-100"
                alt="img"
              />
            </div> -->
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="unimethod-wrapper container">
        <div class="row">
          <div
            class="col-md-4"
            v-for="(section, sectionIndex) in section1"
            :key="sectionIndex"
          >
            <div class="method-card">
              <div class="card-img">
                <img :src="section.image" alt="img" />
              </div>
              <h5>{{ section.title }}</h5>
              <p>
                {{ section.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="uni-plan-wrap">
        <div class="container">
          <div class="_head">
            <h2>Programs</h2>
            <p>
              {{ program.description }}
            </p>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="img-wrap">
                <img :src="nest.image" alt="" />
                <div class="info-layer row align-items-center">
                  <div class="col-md-3">
                    <img src="../assets/nest-thumbnail.png" alt="" />
                  </div>
                  <div class="col-md-5">
                    <div class="star-wrap">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p>231 students</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="content">
                <h3>{{ nest.title }}</h3>
                <p>
                  {{ nest.description }}
                </p>
                <ul
                  class="list-unstyled"
                  v-for="(feature, featureIndex) in nest.features"
                  :key="featureIndex"
                >
                  <li>
                    <img src="../assets/list-icon.svg" alt="" />
                    {{ feature.feature }}
                  </li>
                </ul>
                <router-link :to="{ name: 'Nest' }" class="btn cta-primary"
                  >Learn more</router-link
                >
                <button class="btn cta-clear">get a callback</button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="img-wrap">
                <img :src="pupa.image" alt="" />
                <div class="info-layer row align-items-center">
                  <div class="col-md-3">
                    <img src="../assets/pupa-thumbnail.png" alt="" />
                  </div>
                  <div class="col-md-5">
                    <div class="star-wrap">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p>231 students</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="content">
                <h3>{{ pupa.title }}</h3>
                <p>
                  {{ pupa.description }}
                </p>
                <ul
                  class="list-unstyled"
                  v-for="(feature, featureIndex) in pupa.features"
                  :key="featureIndex"
                >
                  <li>
                    <img src="../assets/list-icon.svg" alt="" />
                    {{ feature.feature }}
                  </li>
                </ul>
                <router-link :to="{ name: 'pupa' }" class="btn cta-primary"
                  >Learn more</router-link
                >
                <button class="btn cta-clear">get a callback</button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-md-5">
              <div class="img-wrap">
                <img :src="skillGrow.image" alt="" />
                <div class="info-layer row align-items-center">
                  <div class="col-md-3">
                    <img src="../assets/skillgro-thumbnail.png" alt="" />
                  </div>
                  <div class="col-md-5">
                    <div class="star-wrap">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <p>231 students</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="content">
                <h3>{{ skillGrow.title }}</h3>
                <p>
                  {{ skillGrow.description }}
                </p>
                <ul
                  class="list-unstyled"
                  v-for="(feature, featureIndex) in skillGrow.features"
                  :key="featureIndex"
                >
                  <li>
                    <img src="../assets/list-icon.svg" alt="" />
                    {{ feature.feature }}
                  </li>
                </ul>
                <button class="btn cta-primary">Learn more</button>
                <button class="btn cta-clear">get a callback</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testimonial-section">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <h2>What did our <span>users</span> say abot us</h2>
              <div class="_slides">
                <div
                  id="carouselExampleIndicators"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div class="carousel-indicators">
                    <button
                      v-for="(testimonial, testimonialIndex) in testimonials"
                      :key="testimonialIndex"
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      :data-bs-slide-to="testimonialIndex"
                      :class="testimonialIndex == 0 ? 'active' : ''"
                      aria-current="true"
                      :aria-label="'Slide ' + testimonialIndex"
                    ></button>
                  </div>
                  <div class="carousel-inner">
                    <div
                      v-for="(testimonial, testimonialIndex) in testimonials"
                      :key="testimonialIndex"
                      class="carousel-item"
                      :class="testimonialIndex == 0 ? 'active' : ''"
                    >
                      <p class="count">
                        {{ testimonialIndex + 1 }}/{{ testimonials.length }}
                      </p>
                      <p class="_comment">
                        {{ testimonial.content }}
                      </p>

                      <h5>
                        {{ testimonial.first_name }} {{ testimonial.last_name }}
                        <br />
                        <span>{{ testimonial.designation }}</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="soon-section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-3">
              <div class="img-wrap">
                <img :src="nestEvent.image" alt="" />
              </div>
            </div>
            <div class="col">
              <div class="_head">
                <h3>{{ nestEvent.title }}</h3>
                <p>{{ nestEvent.start_time }}</p>
              </div>
              <div class="_body">
                <div v-html="nestEvent.description"></div>
                <p class="name">
                  <span>By</span>
                  {{ nestEvent.first_name }} {{ nestEvent.last_name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="faq-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="_content">
                <h3>Frequently asked questions</h3>
                <p>
                  {{ faqDescription.description }}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div
                  class="accordion-item"
                  v-for="(faq, faqIndex) in faqs"
                  :key="faqIndex"
                >
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#flush-collapseOne' + faqIndex"
                      aria-expanded="false"
                      :aria-controls="'flush-collapseOne' + faqIndex"
                    >
                      {{ faq.question }}
                    </button>
                  </h2>
                  <div
                    :id="'flush-collapseOne' + faqIndex"
                    class="accordion-collapse collapse"
                    :aria-labelledby="'flush-headingOne' + faqIndex"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      {{ faq.answer }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import UniLandingService from "@/services/Uni-landingService";
export default {
  name: "unilanding",
  created() {
    this.getTestimonials();
    this.getFaq();
    this.getContents();
  },
  data() {
    return {
      testimonials: [],
      banners: [],
      section1: [],
      program: "",
      pupa: "",
      nest: "",
      skillGrow: "",
      faqDescription: "",
      faqs: []
    };
  },
  methods: {
    getContents() {
      UniLandingService.getContents()
        .then(result => {
          this.banners = result.data.banners;
          this.section1 = result.data.section1;
          this.program = result.data.program;
          this.pupa = result.data.pupa;
          this.nest = result.data.nest;
          this.skillGrow = result.data.skillGrow;
          this.faqDescription = result.data.faqDescription;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getTestimonials() {
      UniLandingService.getTestimonials()
        .then(result => {
          this.testimonials = result.data.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    getFaq() {
      UniLandingService.getFaqs()
        .then(result => {
          this.faqs = result.data.data;
        })
        .catch(error => {
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/solo-event.scss";
h3.active {
  color: #f67f29;
}
</style>
