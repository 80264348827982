import Http from "@/services/Http";
export default {
  getFaqs(data) {
    return Http().post("/common/faqs", data);
  },
  getTestimonials(data) {
    return Http().post("/common/testimonials", data);
  },
  getContents(data) {
    return Http().post("/home/content", data);
  }
};
